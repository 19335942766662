// @ts-check
const path = require('path');
const { initReactI18next } = require('react-i18next');

/**
 * @type {import('next-i18next').UserConfig}
 */ 
 
module.exports = {
  // Frontend locales
  i18n: {
    locales: ['default', 'en-GB', 'en-DE', 'de-DE', 'fr-FR'],
    defaultLocale: 'de-DE',
    localeDetection: false,
  },
  react: { useSuspense: false },
  localePath: path.resolve('./public/locales'),
  // fallbackLng: 'en-GB',
  use: [initReactI18next],
  serializeConfig: false
};

