import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export const getEnvironmentVariable = (environmentVariable: string, name: string): string => {
  if (!environmentVariable) console.error("Couldn't find environment variable:", name);
  return environmentVariable;
};
export const strapiConfig = {
  url: getEnvironmentVariable(publicRuntimeConfig?.NEXT_PUBLIC_STRAPI_URL, 'NEXT_PUBLIC_STRAPI_URL'),
  token: getEnvironmentVariable(publicRuntimeConfig?.NEXT_PUBLIC_STRAPI_TOKEN, 'NEXT_PUBLIC_STRAPI_TOKEN'),
}
