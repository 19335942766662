import { mutate } from 'swr';
import { fetchApiHub } from 'frontastic';

export const createSession = async (redirectUri: string) => {
  const payload = {
    returnUrl: redirectUri,
  };
  const res = await fetchApiHub('/action/payment/createSession', { method: 'POST' }, payload);
  await mutate('/action/payment/createSession', res);
  return res;
};

export const addPayment = async (sessionData: string) => {
  const payload = {
    sessionData: sessionData,
  };

  const res = await fetchApiHub('/action/payment/addPayment', { method: 'POST' }, payload);
  // await mutate('/action/payment/createSession', res);
  return res;
};
