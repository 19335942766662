import { fetchApiHub } from 'frontastic/lib/fetch-api-hub';
import { mutate } from 'swr';

export async function loginSSO(): Promise<{
  url?: string;
  message?: string;
}> {
  return await fetchApiHub('/action/sso/authenticate', {
    method: 'GET',
  });
}

export async function callbackSSO(): Promise<{
  url?: string;
  message?: string;
}> {
  const response = await fetchApiHub('/action/sso/callback' + window.location.search, {
    method: 'GET',
  });

  await mutate('/action/account/getAccount', response);

  return response;
}
