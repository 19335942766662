import { ApolloClient, InMemoryCache } from '@apollo/client';
import { strapiConfig } from 'helpers/strapiConfig';

const client = new ApolloClient({
  uri: `${strapiConfig.url}/graphql`,
  headers: {
    Authorization: `Bearer ${strapiConfig.token}`,
  },
  cache: new InMemoryCache(),
});

export default client;
