import { callbackSSO } from 'frontastic/actions/sso';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export function useCallbackSSO() {
  const router = useRouter();

  useEffect(() => {
    const { session_state, code } = router.query;

    if (router.asPath.split('?')[0] !== '/') {
      return;
    }
    console.log(router.query);
    if (session_state && code) {
      callbackSSO()
        .then((res) => {
          console.log('callback sso frontend res:', res);
          router.push('/');
        })
        .catch((err) => {
          console.log('callback sso frontend err:', err);
          router.push('/');
        });
    }
  }, [router]);
}
