import { useCallbackSSO } from 'helpers/hooks/useCallbackSSO';
import React from 'react';
import { Toaster as ReactToaster } from 'react-hot-toast';

const Toaster = () => {
  useCallbackSSO();
  return <ReactToaster />;
};

export default Toaster;
