import React from 'react';
import type { AppProps } from 'next/app';
import { ApolloProvider } from '@apollo/client';
import { appWithTranslation } from 'next-i18next';
import Toaster from 'components/commercetools-ui/toaster';
import 'tailwindcss/tailwind.css';
import 'styles/app.css';
import 'styles/themes/default.css';
import 'styles/themes/theme1.css';
import 'styles/themes/theme2.css';
import 'styles/themes/theme3.css';
import 'styles/components/slider.css';
import 'styles/components/default-loader.css';
import { FrontasticProvider } from 'frontastic';
import client from '../lib/apolloClient';
import nextI18NextConfig from '../next-i18next.config';

function FrontasticStarter({ Component, pageProps }: AppProps) {
  return (
    <>
      <ApolloProvider client={client}>
        <FrontasticProvider>
          <Component {...pageProps} />
          <Toaster />
        </FrontasticProvider>
      </ApolloProvider>
    </>
  );
}

export default appWithTranslation(FrontasticStarter, nextI18NextConfig);
