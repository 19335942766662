const languageMapper = {
  ['en-GB']: 'en_GB',
  ['de-DE']: 'de_DE',
  ['en-DE']: 'en_DE',
  ['fr-FR']: 'fr_FR',
};

const mapLanguage = (lang) => {
  if (!languageMapper[lang]) {
    console.warn(`Language mapper is missing language ${lang}`);
  }

  //If language is not defined in languageMapper then select first locale
  return languageMapper[lang] || languageMapper[Object.keys(languageMapper)[0]];
};

module.exports = { languageMapper, mapLanguage };
